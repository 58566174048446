<template>
    <div class="pb-10">
        <div class="bg-custom-100 pt-10 pb-24">
            <div class="max-w-7xl mx-auto px-6 lg:px-8">
                <Header :name="$translate('support.title')" :subtitle="$translate('support.description')">
                    <span class="shadow-sm rounded-md" v-if="!table.loading">
                        <button type="button" @click.prevent="tickets.create = true;"
                                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-custom-600 hover:bg-custom-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">
                            <span class="pr-3"><i class="far fa-plus"></i></span>
                            {{ $translate('support.raise-a-ticket') }}
                        </button>
                    </span>
                </Header>
            </div>
        </div>
        <div class="py-10 -mt-24">
            <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
                <div class="grid grid-cols-3 gap-4">
                    <div class="col-span-3">
                        <div class="bg-white shadow sm:rounded-lg overflow-hidden">
                            <DataTable :table="table" :dataset="tickets.list" empty="support.empty">
                                <template #column_status="{row}">
                                    <span class="inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium leading-5 bg-gray-100 text-gray-800">
                                        {{ row.information.status.charAt(0).toUpperCase() + row.information.status.slice(1) }}
                                    </span>
                                </template>
                                <template #image>
                                    <img src="../../assets/images/tickets.svg" class="h-32 m-auto mt-16 mb-8"/>
                                </template>
                                <template #empty>
                                    <span class="inline-flex rounded-md shadow-sm">
                                        <button type="button"
                                                @click.prevent="tickets.create = true;"
                                                class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-custom-500">
                                            {{ $translate('support.raise-a-ticket') }}
                                        </button>
                                    </span>
                                </template>
                            </DataTable>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CreateTicket v-model:visible="tickets.create" @created="ticketCreated" />
    <ViewTicket v-model:visible="tickets.view" :id="tickets.id" />
</template>

<script lang="ts">
    import {defineComponent, reactive} from 'vue';
    import {BaseAction, BaseActionGroup, BaseTable, LinkAction, Pagination, TextColumn} from "@/types/table";
    import {userStore} from "@/store/user";
    import {useAxios} from "@/plugins/axios";
    import CreateTicket from "@/pages/support/CreateTicket.vue";
    import ViewTicket from "@/pages/support/ViewTicket.vue";
    import {Ticket} from "@/interfaces/support";

    export default defineComponent({
        name: "Support",
        components: {
            CreateTicket,
            ViewTicket,
        },
        setup() {

            const axios = useAxios();

            let tickets = reactive({
                create: false,
                view: false,
                id: 0,
                list: []
            });

            //  Create data table object
            const table = reactive(new BaseTable(Pagination.None))

            //  Add table columns
            table.addColumn(new TextColumn('subject', 'support.subject'))
            table.addColumn(new TextColumn('status', 'support.status').hideMobile())

            //  Add view ticket action
            table.addAction(new LinkAction('support.view').onClick((record: Ticket) => {
                    tickets.view = true;
                    tickets.id = (record.id as number);
                })
            );


            const fetch = () => {
                table.loading = true;
                axios.get(`support`).then(response => response.data).then(response => {
                    tickets.list = response.tickets;
                    table.loading = false;
                }).catch(() => {
                    table.loading = false;
                });
            }
            fetch();

            const ticketCreated = () => {
                tickets.create = false;
                fetch();
            }

            return {
                tickets,
                locale: userStore.getState().locale,
                table,
                ticketCreated,
            }
        }
    })
</script>