<template>
    <Drawer :drawer="ticketDrawer"
            :title="$translate(`support.view`)"
            :description="$translate(`support.support`)" width="max-w-3xl">
        <Loading :loading="ticket.loading" v-if="ticket.loading"/>
        <div v-if="!ticket.loading && ticket.exists">
            <dl class="sm:divide-y sm:divide-gray-200">
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                        {{ $translate(`support.submitted`) }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {{ ticket.ago }}
                    </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                        {{ $translate(`support.subject`) }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {{ ticket.subject }}
                    </dd>
                </div>
                <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-gray-500">
                        {{ $translate(`support.question`) }}
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        {{ ticket.question }}
                    </dd>
                </div>
            </dl>
        </div>
        <div class="grid gap-4 grid-columns-1">
            <div class="rounded-md bg-gray-100 m-4 mb-0 p-5" v-for="(message, index) in ticket.conversations"
                 :key="`msg_${index}`">
                <div class="text-sm text-gray-900">
                    {{ message.description }}
                </div>
                <div class="text-sm text-gray-400">
                    {{ message.ago }}
                </div>
            </div>
        </div>
        <template #footer/>
    </Drawer>
</template>

<script lang="ts">
    import {defineComponent, reactive, watch} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {getErrorMessage, useAxios} from "@/plugins/axios";

    export default defineComponent({
        name: "ViewTicket",
        props: {
            id: {
                type: [Number, String],
                default: '',
            },
            visible: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {

            const axios = useAxios();

            const ticket = reactive({
                loading: false,
                exists: true,
                ago: '',
                subject: '',
                question: '',
                conversations: [],
            })

            const ticketDrawer = reactive(new usePromise());
            const show = async () => {
                const confirmed = await ticketDrawer.show();
                emit('update:visible', false);
            }

            watch(() => props.visible, (visible) => {
                if (visible) {
                    show();
                    fetch();
                }
            }, {immediate: true})

            const fetch = () => {
                ticket.loading = true;
                axios.get(`support/${props.id}`)
                    .then(response => response.data)
                    .then(response => {
                        ticket.exists = true;
                        ticket.ago = response.ticket.ago;
                        ticket.subject = response.ticket.subject;
                        ticket.question = response.ticket.description;
                        ticket.conversations = response.ticket.conversations;
                        ticket.loading = false;
                    })
                    .catch(error => {
                        ticket.exists = false;
                        ticket.loading = false;
                        ticketDrawer.hide();
                    });
            }

            return {
                ticketDrawer,
                ticket
            }

        }
    })
</script>