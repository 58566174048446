
    import {defineComponent, reactive} from 'vue';
    import {BaseAction, BaseActionGroup, BaseTable, LinkAction, Pagination, TextColumn} from "@/types/table";
    import {userStore} from "@/store/user";
    import {useAxios} from "@/plugins/axios";
    import CreateTicket from "@/pages/support/CreateTicket.vue";
    import ViewTicket from "@/pages/support/ViewTicket.vue";
    import {Ticket} from "@/interfaces/support";

    export default defineComponent({
        name: "Support",
        components: {
            CreateTicket,
            ViewTicket,
        },
        setup() {

            const axios = useAxios();

            let tickets = reactive({
                create: false,
                view: false,
                id: 0,
                list: []
            });

            //  Create data table object
            const table = reactive(new BaseTable(Pagination.None))

            //  Add table columns
            table.addColumn(new TextColumn('subject', 'support.subject'))
            table.addColumn(new TextColumn('status', 'support.status').hideMobile())

            //  Add view ticket action
            table.addAction(new LinkAction('support.view').onClick((record: Ticket) => {
                    tickets.view = true;
                    tickets.id = (record.id as number);
                })
            );


            const fetch = () => {
                table.loading = true;
                axios.get(`support`).then(response => response.data).then(response => {
                    tickets.list = response.tickets;
                    table.loading = false;
                }).catch(() => {
                    table.loading = false;
                });
            }
            fetch();

            const ticketCreated = () => {
                tickets.create = false;
                fetch();
            }

            return {
                tickets,
                locale: userStore.getState().locale,
                table,
                ticketCreated,
            }
        }
    })
