
    import {defineComponent, reactive, watch} from 'vue';
    import {usePromise} from "@/components/usePromise";
    import {getErrorMessage, useAxios} from "@/plugins/axios";

    export default defineComponent({
        name: "ViewTicket",
        props: {
            id: {
                type: [Number, String],
                default: '',
            },
            visible: {
                type: Boolean,
                default: false,
            },
        },
        setup(props, {emit}) {

            const axios = useAxios();

            const ticket = reactive({
                loading: false,
                exists: true,
                ago: '',
                subject: '',
                question: '',
                conversations: [],
            })

            const ticketDrawer = reactive(new usePromise());
            const show = async () => {
                const confirmed = await ticketDrawer.show();
                emit('update:visible', false);
            }

            watch(() => props.visible, (visible) => {
                if (visible) {
                    show();
                    fetch();
                }
            }, {immediate: true})

            const fetch = () => {
                ticket.loading = true;
                axios.get(`support/${props.id}`)
                    .then(response => response.data)
                    .then(response => {
                        ticket.exists = true;
                        ticket.ago = response.ticket.ago;
                        ticket.subject = response.ticket.subject;
                        ticket.question = response.ticket.description;
                        ticket.conversations = response.ticket.conversations;
                        ticket.loading = false;
                    })
                    .catch(error => {
                        ticket.exists = false;
                        ticket.loading = false;
                        ticketDrawer.hide();
                    });
            }

            return {
                ticketDrawer,
                ticket
            }

        }
    })
